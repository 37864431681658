<template>
  <div class="wrapper">
    <el-dialog title="线下还款" :visible.sync="outerVisible" width="70%">
      <!-- <el-dialog width="30%" title="内层 Dialog" :visible.sync="innerVisible" append-to-body> </el-dialog> -->

      <div class="main-box">
        <div class="main" v-for="(item, i) in unpay_list" :key="i">
          <el-descriptions style="margin-bottom:20px;" :title="`订单号：${item.orderNo}`" direction="vertical" :column="8" border>
            <el-descriptions-item label="商品描述" label-class-name="my-label">{{ item.orderGoods.skuTitle }}</el-descriptions-item>
            <el-descriptions-item label="规格" label-class-name="my-label">
              <div v-for="(sku, i) in item.orderGoods.skuAttrList" :key="i">{{ sku.group }}： {{ sku.value }}</div>
            </el-descriptions-item>
            <el-descriptions-item label="数量" label-class-name="my-label">{{ item.totalOrderNum }}</el-descriptions-item>
            <el-descriptions-item label="总租金" label-class-name="my-label">{{ item.rentPrice }}</el-descriptions-item>
            <el-descriptions-item label="租期" label-class-name="my-label">{{ item.billPeriodNum }}期</el-descriptions-item>
            <el-descriptions-item label="买断尾款" label-class-name="my-label">{{ item.buyoutPrice }}</el-descriptions-item>
          </el-descriptions>

          <div class="list">
            <el-table
              :ref="`table${i}`"
              :data="item.billList"
              style="width: 100%"
              @selection-change="handleSelectionChange($event, i)"
              @select="changeSelect($event, i)"
              @select-all="changeAll($event, i)"
              header-row-class-name="tp-table__header"
            >
              <el-table-column type="selection" min-width="50"> </el-table-column>
              <el-table-column align="center" prop="billNum" label="期数" min-width="50"> </el-table-column>
              <el-table-column align="center" prop="billDate" label="账期" min-width="120"> </el-table-column>
              <el-table-column align="center" prop="principalAmount" label="金额" min-width="100"> </el-table-column>
              <el-table-column align="center" prop="overdueAmount" label="逾期金额" min-width="100"> </el-table-column>
              <el-table-column align="center" prop="offAmount" label="减免金额" min-width="100"> </el-table-column>
              <el-table-column align="center" prop="leftPayAmount" label="待结金额" min-width="100"> </el-table-column>
              <el-table-column align="center" prop="paidAmount" label="实付金额" min-width="100"> </el-table-column>
              <el-table-column align="center" prop="statusName" label="状态" min-width="100"> </el-table-column>
            </el-table>
          </div>
          <div class="maiduan-checkbox" v-show="item.buyoutPrice - 0 > 0">
            <div class="title">买断尾款</div>
            <el-checkbox v-model="item.isBuyoutPay" @change="changeBuy" :disabled="item.selectedList.length != item.billList.length"
              >金额</el-checkbox
            >
            <span class="amount">{{ item.buyoutPrice }}元</span>
          </div>
          <el-divider></el-divider>
        </div>
      </div>

      <div class="result">
        <div class="select-num">
          已勾选 <span>{{ selectNum }}</span> 期
        </div>
        <div class="maiduan">
          <span>{{ buy.toFixed(2) }}</span> 元买断尾款
        </div>
        <div class="left-pay-num">
          待结金额总计 <span>{{ leftPayAmountTotal }}</span> 元
        </div>
      </div>
      <div class="pay-amount">
        <span class="item">实付金额</span>
        <span class="item">
          <el-input-number
            v-model="payAmount"
            size="mini"
            placeholder="请输入"
            :precision="2"
            :controls="false"
            :step="0.01"
            step-strictly
            :min="null"
            @change="changePayAmount"
          ></el-input-number>
          元
        </span>
        <span class="item">
          <el-button type="primary" size="mini" @click="confirmReduce">确认</el-button>
        </span>
        <span style="" class="item">本次共计减免{{ offAmountTotal.toFixed(2) }}元</span>
      </div>
      <div style="margin-top:20px">
        <el-button type="" size="small" @click="outerVisible = false">取消</el-button>
        <el-button :disabled="is_pay" v-checkbtn="isShowBtn(AUTH_BTN.finance_count_list_offline)" type="primary" size="small" @click="save"
          >线下还款</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderBillListAPI, handleOrderBillOffLinePayAPI, getOfflineResultAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { Loading } from 'element-ui'
const columns = [
  {
    label: '流水号',
    prop: 'billNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['billNo']}</p>
    }
  },

  {
    label: '期数',
    prop: 'billNum',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['billNum']}</p>
    }
  },
  {
    label: '账期',
    prop: 'billDate',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['billDate']}</p>
    }
  },
  {
    label: '金额',
    prop: 'principalAmount',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['principalAmount']}</p>
    }
  },
  {
    label: '待结金额',
    prop: 'leftPayAmount',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['leftPayAmount']}</p>
    }
  },
  {
    label: '已付金额',
    prop: 'paidAmount',
    minWidth: '130',
    customRender(h, row) {
      return <p>{row['paidAmount']}</p>
    }
  },
  {
    label: '状态',
    prop: 'statusName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['statusName']}</p>
    }
  },
  {
    label: '付款时间',
    prop: 'createTime',
    minWidth: '150',
    customRender(h, row) {
      return <p>缺字段</p>
    }
  },
  {
    label: '减免金额',
    prop: 'offAmount',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['offAmount']}</p>
    }
  }
]
export default {
  name: 'OfflinePay',
  props: ['orderNo'],
  mixins: [authBtnMixin],
  data() {
    return {
      outerVisible: false,
      columns,
      // currentPage: 1,
      // pageSize: 10,
      // total: 0,
      // list: [],
      // detailInfo: {},
      // selectedList: [],
      payAmount: '',
      unpay_list: [],
      is_pay: true,
      is_close: false,
      loading: null
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    // 共计买断尾款
    buy() {
      return (
        this.unpay_list
          .filter(item => item.isBuyoutPay)
          .reduce((total, item) => {
            return total + Number(item.buyoutPrice) * 100
          }, 0) / 100
      )
    },
    // 所有选中的账单数
    selectNum() {
      return this.unpay_list.reduce((total, item) => {
        return total + item.selectedList.length
      }, 0)
    },
    // 所有选中的待结算
    leftPayAmountTotal() {
      return (
        this.unpay_list.reduce((total, item) => {
          return (
            total +
            item.selectedList.reduce((totalSelected, item) => {
              return totalSelected + Number(item.leftPayAmount) * 100
            }, 0)
          )
        }, 0) / 100
      )
    },
    // 所有选中的减免
    offAmountTotal() {
      return (
        this.unpay_list.reduce((total, item) => {
          return (
            total +
            item.selectedList.reduce((totalSelected, item) => {
              return totalSelected + Number(item.offAmount) * 100
            }, 0)
          )
        }, 0) / 100
      )
    }
  },
  watch: {
    unpay_list: {
      deep: true,
      handler(newV, oldV) {
        this.unpay_list.forEach(item => {
          if (item.selectedList.length != item.billList.length) {
            if (this.is_pay) {
              item.isBuyoutPay = false
            }
          }
        })
        // true 就不是全选
        const all_bill_check = this.unpay_list.some(item => item.billList.length == item.selectedList.length)
        // true  买断尾款就不是全选
        const all_bill_buy = this.unpay_list.some(item => !item.isBuyoutPay)

        if (all_bill_check) {
          this.is_close = true
        } else {
          this.is_close = false
        }
      }
    }
  },

  methods: {
    // 修改实付金额
    changePayAmount() {
      console.log('修改实付金额')
      this.is_pay = true
    },
    // 确定
    async confirmReduce() {
      // 勾选账单
      const is_select = this.unpay_list.some(item => item.selectedList.length > 0)
      if (!is_select) return this.$message.error('请勾选账单！')
      if (this.payAmount - 0 <= 0) return this.$message.error('实付金额不正确！')
      this.is_pay = false
      const select_unpay_list = this.unpay_list.filter(item => item.selectedList.length > 0)
      const payList = select_unpay_list.map(item => {
        return {
          orderNo: item.orderNo,
          enableBuyout: item.isBuyoutPay,
          billNoList: item.selectedList.map(item => {
            return item.billNo
          })
        }
      })
      const resultData = {
        payAmount: this.payAmount || 0,
        payList
      }

      // localStorage.setItem('unpay', JSON.stringify(this.unpay_list))

      const calc_res = await getOfflineResultAPI(resultData)

      calc_res.billResultList.forEach(item => {
        this.unpay_list.forEach(unpay => {
          if (unpay.orderNo == item.orderNo) {
            this.$set(unpay, 'billList', item.billList)
          }
        })
      })

      // 保持原有的勾选状态
      this.unpay_list.forEach((item, index) => {
        if (item.selectedList.length > 0) {
          item.billList.forEach((bill, i) => {
            item.selectedList.forEach(select => {
              if (select.billNum == bill.billNum) {
                this.$nextTick(() => {
                  this.$refs[`table${index}`][0].toggleRowSelection(bill, true)
                })
              }
            })
          })
        }
      })

      this.is_pay = false

      // const local_list = JSON.parse(localStorage.getItem('unpay'))

      // 买断尾款保持原有状态
      // this.unpay_list.forEach(item => {
      //   local_list.forEach(local => {
      //     if (local.orderNo == item.orderNo) {
      //       console.log(local.isBuyoutPay)
      //       item.isBuyoutPay = local.isBuyoutPay
      //     }
      //   })
      // })
    },
    // 列表
    async getOrderBillList() {
      const res = await getOrderBillListAPI(this.orderNo.join())
      this.unpay_list = res.map(item => {
        return {
          ...item,
          selectedList: []
        }
      })

      // localStorage.setItem('unpay_list', JSON.stringify(this.unpay_list))
    },
    // 点击复选框
    handleSelectionChange(val, i) {
      this.unpay_list[i].selectedList = val
    },
    changeSelect(val, i) {
      // console.log(val, i)
      this.is_pay = true
    },
    changeAll() {
      this.is_pay = true
    },
    changeBuy() {
      this.is_pay = true
    },
    // 线下还款按钮
    save() {
      const select_unpay_list = this.unpay_list.filter(item => item.selectedList.length > 0)
      const payList = select_unpay_list.map(item => {
        return {
          orderNo: item.orderNo,
          enableBuyout: item.isBuyoutPay,
          billNoList: item.selectedList.map(item => {
            return item.billNo
          })
        }
      })
      const resultData = {
        payAmount: this.payAmount || 0,
        payList
      }

      this.$confirm('您是否确定线下支付?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      })
        .then(() => {
          this.loading = Loading.service({
            lock: true, //是否锁定屏幕的滚动
            spinner: 'el-icon-loading',
            text: '还款中...', //loading下面的文字
            background: 'rgba(0, 0, 0, 0.0)', //loading的背景色
            target: 'body' //loading显示在容器
          })
          
          handleOrderBillOffLinePayAPI(resultData).then(() => {
            this.is_pay = true
            this.payAmount = ''

            // if (this.is_close) {
            //   this.$emit('update')
            //   this.outerVisible = false
            //   return
            // }

            setTimeout(() => {
              this.loading.close()
              this.$message.success('还款成功')
              this.$emit('update')
              this.outerVisible = false
            }, 500)

            // this.getOrderBillList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .my-label {
    color: #1a1e25;
    background: #fafafa;
  }
  .tp-table__header {
    height: 48px;
    th {
      font-size: 14px;
      font-weight: 400;
      // color: #626e7c;
      color: #1a1e25;
      background-color: #fafafa;
      // border-top: 1px solid #ccc;
      // border-bottom: 1px solid #ccc;
      // padding-left: 10px;
    }
  }
  .maiduan-checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
    .title {
      font-weight: 600;
      margin-bottom: 5px;
    }
    .amount {
      margin-left: 10px;
    }
  }
  .result {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .select-num,
    .left-pay-num,
    .maiduan {
      margin-right: 20px;
      span {
        color: red;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .pay-amount {
    margin-top: 15px;
    .item {
      margin-right: 10px;
    }
  }

  .main-box {
    max-height: 500px;
    overflow-y: scroll;
  }
}
</style>
